import React from "react"

import Layout from "../components/layout"
import Snippet from "../components/snippet"

const NotFoundPage = ({ data }) => (
	<Layout
		meta={{
			title: "404",
			description: "Accento is an amazing Java/Web/Dev conference - and yet, mistakes happen",
			path: "/404",
		}}
	>
		<Snippet id="404" />
	</Layout>
)

export default NotFoundPage
